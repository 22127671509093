import { useState, useContext } from 'react';

import { Row } from 'antd';

import Button from '@components/Button';
import NeedHelp from '@components/NeedHelp';
import NextLink from '@components/NextLink';
import Text from '@components/Text';
import { AppContext } from '@lib/appContext';
import { UserContext } from '@lib/userContext';
import { APP_LINKS } from '@utils/constants';
import { ROUTES } from '@utils/pages';

import {
  Logo,
  Wrapper,
  StoreBtn,
  StyledContainer,
  Main,
  StyledLink,
} from './styles';

const storeLinks = [
  {
    name: 'GooglePlay',
    link: APP_LINKS.PLAYSTORE,
    image: '/images/btn-playstore.svg',
  },
  {
    name: 'AppleStore',
    link: APP_LINKS.APPSTORE,
    image: '/images/btn-appstore.svg',
  },
];

const LandingPage = () => {
  const { user } = useContext(UserContext);
  const { extraParams } = useContext(AppContext);
  const [showNeedHelp, setShowNeedHelp] = useState(false);

  return (
    <>
      <Wrapper>
        <StyledContainer>
          <Row justify="space-between" align="middle">
            <Logo />
            <Button
              onClick={() => setShowNeedHelp(true)}
              icon={<img src="/icons/phone.svg" />}
              text="Need help?"
              type="tertiary"
            />
          </Row>
          <Row align="middle">
            <Main>
              <Text size="h1" color="springWood" marginB="md" block>
                Group ordering for Businesses and Events
              </Text>
              <Text size="large" color="white">
                Elevating Expectations for Food Delivery
              </Text>
              <Row>
                <NextLink href={ROUTES.MENU.route + extraParams}>
                  <Button
                    marginT="xl"
                    marginR="md"
                    text="View Menu"
                    type="secondary"
                  />
                </NextLink>
                {!user && (
                  <NextLink href={ROUTES.SIGN_IN.route + extraParams}>
                    <Button
                      icon={<img src="/icons/user.svg" />}
                      marginT="xl"
                      text="Sign In"
                      type="tertiary"
                      light
                    />
                  </NextLink>
                )}
              </Row>
            </Main>
          </Row>
          <footer>
            <Row>
              <Text size="large" color="white" marginB="xl">
                For individual ordering, please download our Mobile App.
              </Text>
            </Row>
            <Row>
              {storeLinks.map((link) => (
                <StyledLink target="_blank" href={link.link} key={link.name}>
                  <StoreBtn src={link.image} />
                </StyledLink>
              ))}
            </Row>
          </footer>
        </StyledContainer>
      </Wrapper>
      <NeedHelp visible={showNeedHelp} setShowNeedHelp={setShowNeedHelp} />
    </>
  );
};

export default LandingPage;
