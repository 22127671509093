import Link from 'next/link';

interface Props {
  href: string;
  children: React.ReactNode;
}

/* This component was created because of Next's issue with Links and functional components. https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
 */
const NextLink = ({ href, children, ...props }: Props) => (
  <Link href={href} {...props}>
    <a>{children}</a>
  </Link>
);

export default NextLink;
