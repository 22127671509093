import styled from 'styled-components';

import Container from '@components/Container';
import theme from '@theme';

const { margin, padding } = theme;

export const Wrapper = styled.div`
  background: url('/images/landing-page.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  padding-top: ${padding.xl};
  padding-bottom: ${padding.xxl};
`;

export const StyledContainer = styled(Container)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr auto;
  grid-gap: 10px;
`;

export const Logo = styled.img.attrs({
  src: '/images/logo-light.svg',
})``;

export const StoreBtn = styled.img`
  height: 48px;
  &:not(:last-child) {
    margin-right: ${margin.md};
  }
`;

export const Main = styled.div`
  max-width: 540px;
`;

export const StyledLink = styled.a`
  &:not(:last-child) {
    margin-right: ${margin.md};
  }
`;
