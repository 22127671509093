import styled from 'styled-components';

import theme from '@theme';

const { breakpoints } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;

  @${breakpoints.mobile} {
    padding: 0 20px;
  }
`;
