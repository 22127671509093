import LandingPage from '@containers/LandingPage';
import PublicLayout from '@containers/PublicLayout';
import PageTitle from '@lib/PageTitle';
import { ROUTES } from '@utils/pages';

const Index = () => (
  <PublicLayout>
    <PageTitle title={ROUTES.LANDING_PAGE.title} />
    <LandingPage />
  </PublicLayout>
);

export default Index;
