import Content from './Content';

import { StyledModal } from './styles';

interface Props {
  visible: boolean;
  setShowNeedHelp: (visible: boolean) => void;
}

const NeedHelp = ({ visible, setShowNeedHelp }: Props) => (
  <StyledModal
    centered
    width={500}
    footer={null}
    onCancel={() => setShowNeedHelp(false)}
    visible={visible}
  >
    <Content />
  </StyledModal>
);

export default NeedHelp;
